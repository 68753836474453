<template>
  <!-- Face Registration Modal -->
  <v-dialog
    v-model="faceRegistrationModal"
    width="500"
    transition="dialog-top-transition"
    @click:outside="resetFaceRegistration(),onStop()"
  >
    <v-card>
      <v-toolbar color="white" class="headline" dense
        >Face Registration</v-toolbar
      >
      <v-card-text class="mt-4">
        <div class="caption">
          Please upload your image for face registration.
        </div>
        <div class="col-md-12 col-lg-12 col-xl-12 img-col mt-5">
          <!-- When web cam is in use -->
          <div v-if="startCam">
            <div class="start-camera text-center">
              <div>
                <web-cam
                  :deviceId="deviceId"
                  @cameras="onCameras"
                  @error="onError"
                  height="250px"
                  ref="webcam"
                  width="100%"
                />
              </div>
              <div class="row">
                <div class="col-md-12" style="padding-bottom: 2px">
                  <button
                    @click="onCapture"
                    class="my-btn-img text-center mt-2"
                    type="button"
                  >
                    Capture Photo
                  </button>
                </div>
                <div class="col-md-12" style="padding-bottom: 2px">
                  <button
                    @click="cancelWebUpload"
                    class="my-btn-img text-center mt-2"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- When web cam not in use -->
          <div
            class="row ml-0 mr-0 align-items-center displayImage text-center"
            v-if="!startCam"
          >
            <!-- upload image div -->
            <div class="text-center" v-if="upload_src">
              <label class="fileContainer">
                <div class="img-text">
                  <b style="color: #2758f6; font-weight: 100"
                    >Upload an image</b
                  >
                  <br />upto the size of 1MB
                </div>
                <input
                  @change="onFileChange"
                  accept="image/png, image/jpg, image/jpeg"
                  data-vv-as="Image"
                  name="logo"
                  type="file"
                />
              </label>
              <!-- upload from webcam button -->
              <button
                @click="openWebCam"
                class="my-btn-img text-center mt-3"
                type="button"
              >
                Upload From WebCam
              </button>
            </div>
            <!-- User display image  -->
            <div v-if="image_source">
              <!-- loading div -->
              <div
                class="spinner-border text-primary spinner-set"
                role="status"
                v-if="showSpinner"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <!-- user image display-->
              <img
                :src="cropped_file_url"
                @error="
                  $event.target.src = require('@/assets/images/no-member.svg')
                "
                class="user-image"
                v-bind:class="{ blur: showSpinner }"
              />
              <!-- change image button -->
              <div class="margin-button">
                <button
                  v-if="cropped_file_url == '/img/no-member.31f7c43e.svg'"
                  @click="removeImage"
                  class="my-btn-img text-center mt-3"
                  type="button"
                >
                  Upload Image
                </button>
                <button
                  v-else
                  @click="removeImage"
                  class="my-btn-img text-center mt-3"
                  type="button"
                >
                  Change Image
                </button>
              </div>
            </div>
            <!-- Cropper image div -->
            <div v-if="crop_src">
              <!-- Image cropper -->
              <imageCroper
                :imgSrc="image_src"
                :resizeable="true"
                @croped_file="attach_crop_file($event)"
                alt="User Image"
                v-if="uploaded"
              />
              <!-- Cancel button -->
              <div class="button-margin mb-n10">
                <button
                  @click="cancelUpload"
                  class="my-btn-img button-width text-center mt-4"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </div>

            <!-- User display image preview  -->
            <div v-if="image_preview">
              <!-- loading div -->
              <div
                class="spinner-border text-primary spinner-set"
                role="status"
                v-if="showSpinner"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <!-- user preview image display-->
              <img
                :src="cropped_file"
                @error="
                  $event.target.src = require('@/assets/images/no-member.svg')
                "
                class="user-image"
                v-bind:class="{ blur: showSpinner }"
              />
              <!-- success image -->
              <img
                class="success-tick"
                height="30"
                src="../../assets/images/success-image.svg"
                width="30"
              />
              <!-- change image button -->
              <div class="margin-button">
                <button
                  @click="removeImage"
                  class="my-btn-img text-center mt-3"
                  type="button"
                >
                  Change Image
                </button>
              </div>
            </div>
            <!-- Error div-->
            <div class="text-center">
              <span class="error-msg ml-2">{{ errorMsg }}</span>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          text
          @click="
            (faceRegistrationModal = false),
              (faceRegisterMember = ''),
              onStop(),
              resetFaceRegistration()
          "
        >
          Cancel
        </v-btn>
        <v-btn
          color="success"
          outlined
          text
          @click="submitFaceToApi(faceRegisterMember)"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { WebCam } from "vue-web-cam";
import { find, head } from "lodash";
import { baseURL } from "@/assets/js/urls";
export default {
  name: "FaceRegistrationModal",
  components: {
    WebCam,
    imageCroper: () => import("@/components/includes/helper/ImageCropper"),
  },
  data() {
    return {
      // Face Registration Variables
      faceRegistrationModal: false,
      faceRegisterMember: "",
      img: null,
      image_src: "",
      camera: null,
      deviceId: null,
      devices: [],
      uploaded: false,
      is_cropped: false,
      cropped_file_url: require("../../assets/images/no-member.svg"),
      showSpinner: false,
      startCam: false,
      message: "upload",
      picturePath: "",
      uploadResult: "",
      upError: "",
      image_preview: false,
      crop_src: false,
      upload_src: false,
      image_source: true,
      base64: "",
      errorMsg: "",
    };
  },
  mounted() {
    this.$eventBus.$on("faceRegistration", (member) => {
      this.resetFaceRegistration();
      this.fetchPreviousImage(member);
      this.faceRegistrationModal = true;
      this.faceRegisterMember = member;
    });
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      let first = head(this.devices);
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
  methods: {
    /**
     * This function is resposible for
     * image validation
     */
    isImageValid(imageBase64) {
      if (!this.$socket.connected) {
        this.showSpinner = false;
        this.uploadResult =
          "Oops! There was a connectivity issue. Please try again in few moments.";
        this.upError = this.uploadResult;
        this.cropped_file_url = null;
      } else {
        let parts = imageBase64.split(";");
        let FaceApiBase64 = parts[1].split(",")[1];

        return this.$socket.emit("is_image_valid", FaceApiBase64, (data) => {
          this.uploadResult = data;
          this.showSpinner = false;
        });
      }
    },
    /**
     * This function is resposible for
     * image capturing
     */
    onCapture() {
      this.image_src = this.$refs.webcam.capture();
      this.base64 = this.$refs.webcam.capture();
      this.uploaded = true;
      this.upload_src = false;
      this.image_source = false;
      this.attach_crop_file(this.image_src);
      // this.crop_src = true;
      this.onStop();
      this.startCam = false;
    },
    onStop() {
      if (this.$refs.webcam) {
        this.$refs.webcam.stop();
      }
    },
    onError() {
      try {
        this.onFileChange();
      } catch (error) {
        this.startCam = false;
      }
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    /**
     * This function is resposible for
     * Image Uploading
     */
    onFileChange(e) {
      this.errorMsg = "";
      this.upload_src = false;
      this.image_source = false;
      this.crop_src = true;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let _size = file.size;
      if (_size > 1000000) {
        this.errorMsg = " Image exceed size limit, try again";
        this.crop_src = false;
        this.upload_src = true;
        return;
      }
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image_src = e.target.result;
        this.uploaded = true;
      };
      this.picturePath = file;
      reader.readAsDataURL(file);
    },
    removeImage: function () {
      this.image_source = false;
      this.upload_src = true;
      this.image_preview = false;
      this.image_src = "";
      this.uploaded = false;
    },
    cancelUpload: function () {
      this.crop_src = false;
      this.image_source = true;
      this.image_src = "";
      this.uploaded = false;
    },
    cancelWebUpload: function () {
      this.startCam = false;
      this.image_source = true;
      this.upload_src = false;
    },
    attach_crop_file(file_url) {
      this.crop_src = false;
      this.image_preview = true;
      this.uploadResult = "Please wait...!";
      this.showSpinner = true;
      let file_path = this.dataURLtoFile(file_url, "user_image");
      this.cropped_file = file_url;
      this.picturePath = file_path;
      this.is_cropped = true;

      if (this.$store.state.pf.companies.includes(this.$route.params.id))
        this.isImageValid(this.cropped_file);
      else {
        this.uploadResult = true;
        this.showSpinner = false;
      }
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    openWebCam() {
      this.errorMsg = "";
      this.startCam = true;
      setTimeout(() => this.$refs.webcam.start(), 50);
    },
    resetFaceRegistration() {
      (this.img = null),
        (this.image_src = ""),
        (this.camera = null),
        (this.deviceId = null),
        (this.devices = []),
        (this.uploaded = false),
        (this.is_cropped = false),
        (this.showSpinner = false),
        (this.startCam = false),
        (this.message = "upload"),
        (this.picturePath = ""),
        (this.uploadResult = ""),
        (this.upError = ""),
        (this.image_preview = false),
        (this.crop_src = false),
        (this.upload_src = false),
        (this.image_source = true),
        (this.base64 = ""),
        (this.errorMsg = ""),
        (this.cropped_file_url = require("../../assets/images/no-member.svg"));
    },
    /**
     * This function is resposible for
     * update the member face
     */
    submitFaceToApi(member) {
      if (this.image_src == "") {
        this.$root.$emit("snack_bar", {
          show: true,
          message: "Please upload picture before submit",
          snackbarColor: "red",
        });
        return;
      }
      let payload = {
        company_id: this.$route.params.id,
        member_id: member.id,
        imageBase64: this.image_src,
      };
      this.$store.commit("custom/set_loader", true);
      this.$store
        .dispatch("custom/save_member_face", payload)
        .then((response) => {
          this.$store.commit("custom/set_loader", false);
          this.faceRegistrationModal = false;
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Picture updated Sucessfully",
            snackbarColor: "green",
          });
        })
        .catch((err) => {
          this.$store.commit("custom/set_loader", false);
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Error during upload, please try again",
            snackbarColor: "red",
          });
        });
    },
    /**
     * This function is resposible for
     * fetch previous saved member face
     */
    fetchPreviousImage(member) {
      let payload = {
        company_id: this.$route.params.id,
        member_id: member.id,
      };
      this.$store.commit("custom/set_loader", true);
      this.$store
        .dispatch("custom/get_previous_image", payload)
        .then((response) => {
          this.$store.commit("custom/set_loader", false);
          if (response) {
            this.cropped_file_url = response
              ? baseURL.API_URL + "storage/" + response
              : require("../../assets/images/no-member.svg");
          } else {
            this.cropped_file_url = response
              ? response
              : require("../../assets/images/no-member.svg");
          }
        })
        .catch((err) => {
          this.$store.commit("custom/set_loader", false);
        });
    },
  },
};
</script>

<style scoped>
/* New Styling */
select {
  min-width: 100% !important;
}

.displayImage {
  margin: 0 auto;
  display: block;
}

.custom-file {
  width: auto;
}

.error-msg {
  color: #e23719;
  font-size: 12px;
}

.custom-file .custom-file-label {
  background: blue;
  color: #fff;
  border-radius: 0;
  border-color: blue;
}

.custom-file-label:after {
  content: none;
}

.p-text {
  font-size: 12px;
  padding-top: 2px;
}
label.fileContainer [type="file"] {
  display: block;
  filter: alpha(opacity=0);
  opacity: 0;
  text-align: right;
  cursor: pointer;
}

label.fileContainer .img-text {
  margin-top: 39px;
}

label.fileContainer {
  display: block;
  overflow: hidden;
  position: relative;
  font-size: 13px;
  border: dashed 2px #cbdaf2;
  padding: 42px 6px;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: rgba(240, 248, 255, 0.93);
  box-shadow: 0 2px 4px 0 rgba(227, 227, 227, 0.45);
  color: #a2b7d9;
  letter-spacing: 0.15px;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
  background-image: url("../../assets/images/dashboard_icons/user_profile.svg");
  background-repeat: no-repeat;
}

.fileContainer:hover {
  cursor: pointer !important;
  transform: scale(1.02);
}

.user-image {
  width: 150px;
  height: 150px;
  -o-object-fit: fill;
  object-fit: fill;
  border-radius: 50%;
}

.start-camera video {
  width: 150px;
  height: 150px;
  -o-object-fit: fill;
  object-fit: fill;
  border-radius: 50%;
}
</style>