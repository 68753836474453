import { render, staticRenderFns } from "./FaceRegistrationTeamMember.vue?vue&type=template&id=0ce23c3b&scoped=true"
import script from "./FaceRegistrationTeamMember.vue?vue&type=script&lang=js"
export * from "./FaceRegistrationTeamMember.vue?vue&type=script&lang=js"
import style0 from "./FaceRegistrationTeamMember.vue?vue&type=style&index=0&id=0ce23c3b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ce23c3b",
  null
  
)

export default component.exports